import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import BooksLoop from "../components/booksLoop"
import SEO from '../components/seo'
import Pagenation from '../components/pagination'
import { BookListWrapper } from '../style/common/common'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const BooksArchive = ({ data, location, pageContext }) => {
  const title = "刊行書籍"
  const BooksArchivePosts = data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map((edge, index) => <BooksLoop post={edge.node} key={index}/>)
  // const { breadcrumb: { crumbs } } = pageContext
  const crumbs = [
    {
      pathname: '/',
      crumbLabel: 'トップ'
    },
    {
      pathname: '/books',
      crumbLabel: '刊行書籍'
    }
  ]

  return (
    <Layout
      location={location}
      title={title}
    >
      <SEO
        title={`${title} | ${data.site.siteMetadata.title}`}
      />
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" / "
        // crumbLabel="刊行書籍"
      />
      <div className="BookListHeader">
      </div>
      <BookListWrapper>
        {BooksArchivePosts}
      </BookListWrapper>
      <Pagenation pageContext={pageContext} />
    </Layout>
  )
}

export default BooksArchive

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {posttype: {eq: "books"}}}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY.MM.DD")
            title
            author
            category
            pubDate
            thumb {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
            badge
          }
        }
      }
    }
  }
`
